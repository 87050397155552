.page-products {
    aside {
        height: calc(100vh - 200px);
        overflow: scroll;
        position: sticky;
        top: 225px;
        order: 3 !important;
        @include respond-to('md') {
            order: 1 !important;
            height: auto;
            overflow: auto;
            position: static;
            top: 0;
        }
    }
    .block-reorder {
        display: none;
    }
    .breadcrumbs {
        .items {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            line-height: 1.2;

            .item {
                display: inline-flex;
                align-items: center;
                font-size: 0.9em;

                &:not(:last-child)::after {
                    content: '/';
                    margin: 0 6px;
                    color: #888;
                }

                a {
                    font-size: 16px;
                }
            }
        }
    }
}
.catalog-category-view {
    .wishlist-widget {
        display: none;
    }
    li {
        @include respond-to('md') {
            max-height: none;
        }
        .product-item {
            margin-bottom: 30px;
            box-shadow: unset;
            height: 100%;
            @include respond-to('md') {
                margin-bottom: 15px;
            }
        }
    }
    #product-list {
        @include respond-to('md') {
            padding-top: 0;
        }
    }
    #category-view-container {
        padding-top: 0;
        @include respond-to('md') {
            display: none;
        }
        .category-image {
            width: 100%;
            img {
                width: 100%;
                height: 290px;
                object-fit: cover;
            }
        }
    }
    #filters-heading {
        @include respond-to('sm') {
            margin-top: 16px;
            border: 1px solid $gray2;
            padding: 5px;
            border-radius: 3px;
            margin-bottom: 0;
            button {
                margin-bottom: 0;
            }
        }
    }
    #layer-product-list {
        position: relative;
        > section {
            padding-top: 5px;
        }
    }
    .element-pagination {
        transition: .3s all;
        border: 1px solid $cartBg;
        margin-right: 2px;
        &:hover {
            transition: .3s all;
            background: $gray0;
        }
        &[aria-current="page"] {
            border-color: $lilou-black;
        }
    }
    #wishlist-sidebar {
        display: flex;
        align-items: center;
        .counter {
            margin-left: 5px;
        }
    }
    .actions-primary {
        button {
            width: 100%;
        }
    }
    .category-title-container {
        padding-left: 0;
    }
    .toolbar-amount {
        display: flex;
        align-items: center;
        h1 {
            font-weight: 600;
            font-size: 2.2rem;
            line-height: 2.2rem;
            display: inline-block;
            margin-right: 5px;
            position: relative;
            top: -4px;
            color: $lilou-black;
            @include respond-to('sm') {
                font-size: 1.5rem;
                line-height: 1.5rem;
            }
        }
    }
    .sorter-options {
        border: 0;
        border-bottom: 1px solid;
        border-radius: 0;
    }
    .swatch-option-link-layered {
        border: 0;
        padding: 0;
        span {
            font-size: 14px;
        }
    }
    .price-to {
        display: none;
    }

    .toolbar-sorter {
        .select-div-custom {
            position: relative;
            min-width: 215px;
        }
        button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-color: $cartBg;
            color: $lilou-black;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $lilou-black;
            box-shadow: none;
            outline: none;
            padding: 10px 8px;
            font-size: 16px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            transition: all .3s ease;
            &:focus {
                outline: none;
            }
        }
        .options {
            position: absolute;
            width: 100%;
            border-radius: 0;
            border: 1px solid $lilou-black;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            z-index: 10;
            margin-top: -5px;
            a {
                display: block;
                padding: 0.5rem 1rem;
                text-align: left;
                color: $lilou-black;
                font-size: 16px;
                line-height: 18px;
                text-decoration: none;
                transition: all .3s;
                &:hover {
                    background-color: $lilou-black;
                    color: $cartBg;
                    transition: all .3s;
                }
            }
        }
    }
    .breadcrumbs {
        .container {
            padding: 0px;
        }
    }
    .ln_overlay {
        opacity: 1;
        background: rgba(255,255,255, 0.5);
        img {
            width: 100px;
            left: calc(50% - 50px);
        }
    }
}

#filters-content {
    div:first-child {
        margin-top: 0;
    }
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 15px;
    h3 {
        span {
            font-size: 20px;
        }
    }
    .filter-option {
        border-bottom: 1px solid $border-gray;
        padding-bottom: 15px;
        margin-bottom: 15px;
        .filter-options-content {
            li {
                button {
                    text-align: left;
                    gap: 10px;
                }
            }
        }
    }
    .swatch-attribute {
        &.alfabet_swatch, &.cyfry_swatch {
            .swatch-attribute-options {
                grid-template-columns: repeat(auto-fill, minmax(3rem, 1fr));
                grid-auto-rows: 1fr;
                grid-gap: 5px;
                &::before {
                    content: '';
                    width: 0;
                    padding-bottom: 100%;
                    grid-row: 1 / 1;
                    grid-column: 1 / 1;
                }
                > *:first-child {
                    grid-row: 1 / 1;
                    grid-column: 1 / 1;
                }
                .swatch-option {
                    border: 1px solid $border-gray;
                    text-align: center;

                    span {
                        margin-left: 0;
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}

form.product-item, div.product-item {
    padding: 0;
    .product-image-photo {
        background-color: $cartBg;
        width: 100%;
        position: relative;

        img {
            transition: opacity 0.3s ease-in-out;
            &.hover-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
        &:hover img.hover-image {
            opacity: 1;
        }
        &:hover img.primary-image {
            opacity: 0;
        }
    }
    .product-hover-image {
        background-color: $cartBg;
    }
    .product-item-link {
        display: block;
        padding-right: 15px;
        font-size: 1rem;
        min-height: 60px;
    }
    .product-action-buttons {
        button, a  {
            padding: 10px 5px;
            line-height: 0;
        }
        .unavailable {
            line-height: 40px;
        }
    }
}

.sidebar-categories-list {
    margin-top: 20px;
    border-bottom: 1px solid $border-gray;

    @include respond-to('sm') {
        display: none;
    }
    li {
        margin-bottom: 10px;
        font-size: 18px;
        a {
            padding-bottom: 2px;
            border-bottom: 2px solid transparent;
        }
        &:hover {
            a {
                border-bottom: 2px solid $lilou-black;
            }
        }
    }
}
.category-description {
    > div {
        padding: 0 !important;
        max-width: none !important;
        > div {
            padding: 0 !important;
            max-width: none !important;
        }
    }
}
.popular-categories {
    ul {
        margin: 30px 0 !important;
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid black;
            flex-wrap: wrap;
            text-align: center;
            transition: all .3s;
            cursor: pointer;
            min-height: 60px;
            &:hover {
                background: rgba(211, 211, 211, .3);
                transition: all .3s;
            }
            a {
                color: black;
                padding: 5px 10px;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
}
